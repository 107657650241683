import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Turkish Get Ups`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 10 Turkish Get Ups (53/35, 5/arm)`}</p>
    <p>{`3 rounds of:`}</p>
    <p>{`1-Legless Rope Climb to 15′`}</p>
    <p>{`10-Ring Dips`}</p>
    <p>{`20-Goblet Squats (53/35)`}</p>
    <p>{`Cash Out: 10 Turkish Get Ups`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</strong></p>
    <p>{`100 Situps for time.`}{`*`}</p>
    <p>{`*`}{`EMOM :20 Plank.`}</p>
    <p><em parentName="p">{`compare to 5/21/16`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`On October 28th The Ville will be hosting a fundraising wod for
LMPD Officer Jason Schweitzer who was tragically killed by a drunk
driver in Lexington, Ky.  An event brite link will be added here soon in
order to sign up and donate and other CrossFit affiliates will host this
wod on the same day as well.`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place this year on November 4th!  Get your teams together now.  More
info to come. Truegritcomp.wordpress.com.***`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      